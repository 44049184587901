<template>
  <div class="checkout">
    <template v-if="completed">
      <img src="../../assets/images/success.svg" />
      <h1>Payment initiated successfully.</h1>
      <p>You can close this window now.</p>
    </template>

    <template v-else-if="canceled">
      <img src="../../assets/images/error.svg" />
      <h1>Payment canceled.</h1>
      <p>Want to try that again?</p>
      <button class="button" @click="showTransfersModal">Retry</button>
    </template>

    <template v-else-if="failed">
      <img src="../../assets/images/error.svg" />
      <h1>Payment failed.</h1>
      <p>Kindly close this window and try a new payment.</p>
    </template>
  </div>
</template>

<script>
import { getTransfersPayment } from "../../utilities/api";

export default {
  states: ["pending", "completed", "canceled", "failed"],
  methods: {
    async getPaymentInfo() {
      const id = this.$route.query.i;

      const result = await getTransfersPayment({ id });
      if (result.success) {
        this.$showLoader(false);
        this.showTransfersModal(result.body);
      }
    },
    async showTransfersModal({
      signature,
      amount,
      reference,
      paymentChannel: { user },
    }) {
      const result = await Transfers.chargeToDefaultAccount({
        signature,
        amount: parseFloat(amount),
        userId: user.id,
        name: user.name,
        transactionReference: reference,
      });

      if (!result) {
        this.currentState = "canceled";
      } else {
        if (result.paymentId) {
          this.currentState = "completed";
        } else {
          this.currentState = "failed";
        }
      }
    },
  },
  mounted() {
    this.$showLoader(true);
    this.getPaymentInfo();
  },
};
</script>

<style lang="scss" scoped>
.checkout {
  padding: 2.4rem;

  img {
    width: 3.6rem;
    margin-bottom: 2.4rem;

    + h1 {
      margin-bottom: 0;

      + p {
        margin-bottom: 2.4rem;
      }

      + button {
        margin-top: 2.4rem;
      }
    }
  }
}
</style>
